<script>
import Vue, { computed, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v2.vue'
import ConfirmationModal from '/~/components/drawer/modals/confirmation-modal.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useEwallet } from '/~/composables/ewallet'
import { useVirtualCards } from '/~/composables/virtual-cards'
import LinkModal from './link-modal.vue'

export default {
  name: 'virtual-card',
  components: {
    BaseAsidePage,
    BaseIcon,
    BaseLoader,
    ConfirmationModal,
    DrawerActions,
    LinkModal,
  },
  setup() {
    const router = useRouter()
    const {
      isFetchingVirtualCards,
      firstVirtualCard,
      fetchVirtualCardSensitiveData,
      lockVirtualCard,
      unlockVirtualCard,
      deleteVirtualCard,
    } = useVirtualCards()
    const { ewalletLabels } = useCms()
    const { ewalletBalance, fetchingEwallet } = useEwallet()

    const isSensitiveDateVisible = ref(false)
    const lockConfirmationModalRef = ref(null)
    const unlockConfirmationModalRef = ref(null)
    const deleteConfirmationModalRef = ref(null)
    const linkModalRef = ref(null)

    const actions = computed(() => {
      return [
        {
          icon: 'ewallet',
          label: `Add Cash to ${ewalletLabels.value.myEwallet}`,
          description: 'From my bank or use redemption code',
          route: { hash: '#profile-add-cash' },
          disabled: firstVirtualCard.value?.isLocked,
        },
        {
          icon: 'menu-new/ewallet/lock',
          label: 'Lock this card',
          description: 'Instantly restrict transactions on this card',
          click: () => {
            lockConfirmationModalRef.value.show()
          },
          hidden: firstVirtualCard.value?.isLocked,
        },
        {
          icon: 'menu-new/ewallet/lock-opened',
          label: 'Unlock this card',
          description: 'Enable transactions through this card ',
          click: () => {
            unlockConfirmationModalRef.value.show()
          },
          hidden: firstVirtualCard.value?.isUnlocked,
        },
        {
          icon: 'menu-new/ewallet/trash',
          label: 'Delete this card',
          description: 'Instantly remove this card from payment method',
          click: () => {
            deleteConfirmationModalRef.value.show()
          },
        },
      ]
    })
    const virtualCardNumber = computed(() => {
      if (!isSensitiveDateVisible.value) {
        return firstVirtualCard.value?.number
      }

      return (
        firstVirtualCard.value?.sensitiveData.number ??
        firstVirtualCard.value.number
      )
    })
    const virtualCardDate = computed(() => {
      if (
        !isSensitiveDateVisible.value ||
        !firstVirtualCard.value?.sensitiveData.expiryMonth ||
        !firstVirtualCard.value?.sensitiveData.expiryYear
      ) {
        return '**/**'
      }

      const { expiryMonth, expiryYear } = firstVirtualCard.value.sensitiveData

      return `${expiryMonth}/${expiryYear.substr(-2)}`
    })
    const virtualCardCVV = computed(() => {
      if (!isSensitiveDateVisible.value) {
        return '***'
      }

      return firstVirtualCard.value.sensitiveData?.verificationCode ?? '***'
    })

    function toggleSensitiveDataVisibility() {
      isSensitiveDateVisible.value = !isSensitiveDateVisible.value

      if (
        !isSensitiveDateVisible.value ||
        firstVirtualCard.value?.sensitiveData.isFetched
      ) {
        return
      }

      fetchVirtualCardSensitiveData(firstVirtualCard.value.id)
    }

    async function onLockConfirmationModalConfirm() {
      try {
        await lockVirtualCard(firstVirtualCard.value.id)
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while locking. Please try again.',
        })
      }
    }

    async function onUnlockConfirmationModalConfirm() {
      try {
        await unlockVirtualCard(firstVirtualCard.value.id)
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while locking. Please try again.',
        })
      }
    }

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deleteVirtualCard(firstVirtualCard.value.id)
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      }
    }

    async function showLinkModal() {
      linkModalRef.value.show()
    }

    watchEffect(() => {
      if (isFetchingVirtualCards.value) {
        return
      }

      if (!firstVirtualCard.value) {
        router.push({ hash: '#profile-home' })
      }
    })

    return {
      firstVirtualCard,
      isFetchingVirtualCards,
      fetchingEwallet,
      ewalletLabels,
      ewalletBalance,
      isSensitiveDateVisible,
      lockConfirmationModalRef,
      unlockConfirmationModalRef,
      deleteConfirmationModalRef,
      linkModalRef,
      actions,
      virtualCardNumber,
      virtualCardDate,
      virtualCardCVV,
      toggleSensitiveDataVisibility,
      onLockConfirmationModalConfirm,
      onUnlockConfirmationModalConfirm,
      onDeleteConfirmationModalConfirm,
      showLinkModal,
      formatDollar,
    }
  },
}
</script>

<template>
  <base-aside-page
    :title="ewalletLabels.ewalletCash"
    no-padding
    :back="{ hash: '#profile-home' }"
  >
    <div class="flex h-full w-full flex-col bg-white px-5 pb-12">
      <template v-if="isFetchingVirtualCards">
        <base-loader />
      </template>
      <template v-else-if="firstVirtualCard">
        <div class="mb-auto grow">
          <div class="text-center text-5xl font-black">
            <template v-if="fetchingEwallet">
              <base-loader />
            </template>
            <template v-else>
              {{ formatDollar(ewalletBalance) }}
            </template>
          </div>
          <div class="max-w-80 relative mx-auto mt-5 h-48">
            <div
              class="relative h-full w-full overflow-hidden rounded-3xl bg-gray-800 px-6 py-4 text-white"
            >
              <div
                class="absolute rounded-full bg-gray-700"
                :style="{
                  top: '-157px',
                  left: '-120px',
                  width: '258px',
                  height: '258px',
                }"
              />
              <div
                class="absolute rounded-full bg-gray-900"
                :style="{
                  bottom: '-96px',
                  right: '-52px',
                  width: '160px',
                  height: '160px',
                }"
              />
              <div
                class="relative z-10 flex h-full flex-col justify-between font-inter"
              >
                <base-icon svg="menu-new/ewallet/mastercard" :size="48" />
                <base-loader
                  v-if="
                    isFetchingVirtualCards ||
                    (firstVirtualCard.sensitiveData.isFetching &&
                      isSensitiveDateVisible)
                  "
                  color="var(--color-white)"
                  class="my-auto"
                />
                <template v-else>
                  <div class="text-2xl font-bold leading-8">
                    {{ virtualCardNumber }}
                  </div>
                  <div
                    class="flex items-center justify-between text-base leading-6 text-zinc-100"
                  >
                    <div class="font-medium">
                      {{ virtualCardDate }}
                    </div>
                    <div class="font-semibold">CVC {{ virtualCardCVV }}</div>
                  </div>
                </template>
              </div>
            </div>
            <transition name="fade">
              <button
                v-if="firstVirtualCard.isUnlocked"
                type="button"
                class="absolute right-0 top-0 z-10 -mr-2.5 -mt-2.5 flex h-12 w-12 items-center justify-center rounded-full bg-primary text-white hover:shadow-lg"
                @click="toggleSensitiveDataVisibility"
              >
                <base-icon
                  v-if="isSensitiveDateVisible"
                  svg="menu-new/ewallet/eye-off"
                  :size="26"
                />
                <base-icon v-else svg="menu-new/ewallet/eye" :size="26" />
              </button>
            </transition>
            <transition name="fade">
              <div
                v-if="firstVirtualCard.isLocked"
                class="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center overflow-hidden rounded-3xl text-white"
                :style="{
                  'backdrop-filter': 'blur(6px)',
                }"
              >
                <div
                  class="absolute rounded-full bg-gray-700 opacity-50"
                  :style="{
                    top: '-214px',
                    left: '-164px',
                    width: '352px',
                    height: '352px',
                  }"
                />
                <div
                  class="absolute rounded-full bg-gray-900 opacity-40"
                  :style="{
                    bottom: '-130px',
                    right: '-71px',
                    width: '218px',
                    height: '218px',
                  }"
                />
                <div
                  class="absolute left-0 top-0 h-full w-full bg-orange-500 opacity-75"
                />
                <base-icon
                  svg="menu-new/ewallet/lock-v2"
                  :size="62"
                  class="relative"
                />
              </div>
            </transition>
          </div>
          <drawer-actions :actions="actions" class="mt-[30px]" />
        </div>
        <div class="mt-[30px] text-center">
          <div class="flex items-center justify-center">
            <div class="h-10 rounded-full border-2 p-2.5">
              <base-icon svg="google-pay" class="h-full" />
            </div>
            <div class="ml-6 h-10 rounded-md border-2 p-2.5">
              <base-icon svg="apple-pay" class="h-full" />
            </div>
          </div>
          <div class="mt-4">
            <div class="font-bold">Use your virtual card anywhere</div>
            <div class="mt-[5px] text-sm text-eonx-neutral-600">
              Link your card to your Google Pay or Apple Pay and use it anywhere
            </div>
            <button
              type="button"
              class="mx-auto mt-4 flex items-center text-sm font-bold text-primary"
              @click="showLinkModal"
            >
              <base-icon svg="plain/info-circle" :size="19" class="mr-[5px]" />
              Learn more
            </button>
          </div>
        </div>
      </template>
    </div>
    <confirmation-modal
      ref="lockConfirmationModalRef"
      type="warning"
      icon="menu-new/ewallet/lock-v2"
      title="Are you sure you want to lock this card?"
      text="By locking this card you will not be able to transact with it. You can easily unlock this card at any time."
      :on-confirm="onLockConfirmationModalConfirm"
    />
    <confirmation-modal
      ref="unlockConfirmationModalRef"
      type="warning"
      icon="purchases/padlock-unlocked"
      title="Are you sure you want to unlock this card?"
      text="By unlocking this card you will be able to transact with it. You can easily lock this card at any time."
      :on-confirm="onUnlockConfirmationModalConfirm"
    />
    <confirmation-modal
      ref="deleteConfirmationModalRef"
      icon="points-only/trash"
      title="Are you sure you want to delete this card?"
      :text="
        firstVirtualCard &&
        `By deleting this card you will remove the card ending in ${firstVirtualCard.number.substr(
          -4
        )} from ${ewalletLabels.yourEwallet} permanently.`
      "
      :on-confirm="onDeleteConfirmationModalConfirm"
    />
    <link-modal ref="linkModalRef" />
  </base-aside-page>
</template>
